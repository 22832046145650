import React from 'react';

import './index.css';

const HeroBody = () => {
  return (
    <section className="salesforce-hero-body-container">
      <p>
        Efficient business automation, powerful customization capability, and an
        easy-to-use interface are just a few of the reasons why Salesforce is
        the world’s #1 Customer Relationship Management (CRM) platform.
        <br />
        <br />
        Salesforce makes it easy for businesses to connect with their customers
        by bringing the analytics, commerce, IT, marketing, service, and sales
        teams together on one, collaborative platform. Best of all, Salesforce
        development focuses on using as little code as possible to keep each
        tool simple and efficient.
      </p>
    </section>
  );
};

export default HeroBody;
