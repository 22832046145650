import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/salesforceSections/HeroBody';
import EllipseQuoteSection from '../../components/TopOrangeEllipseQuote';
import WhiteQuoteSection from '../../components/LightQuote';
import BestBlogs from '../../components/BestBlogs';
import FormSection from '../../components/Form';

const pageTitle = 'Salesforce Development Services | Devetry';
const pageDescription =
  'Explore Salesforce development services from Devetry. Contact us today to learn more.';

const SalesforcePageTemplate = ({ blogs, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="salesforce-section root-container">
        <Hero
          title="Salesforce Development"
          description="Leverage the full power of Salesforce"
          image={heroImage}
          body={<HeroBody />}
        />
        <EllipseQuoteSection
          title="Custom Salesforce Apps"
          text={
            <span>
              If you need a software solution that isn’t so out-of-the-box, no
              problem. While Salesforce development is focused on as little code
              as possible, there are robust development tools that the Devetry
              team uses to write code that runs directly on your Salesforce
              platform. With experience in Salesforce’s proprietary languages,
              SOQL and SOS and APEX, we can quickly turn around a custom app or
              process that your organization can leverage immediately.
            </span>
          }
        />
        <WhiteQuoteSection
          title="Why Devetry"
          text={
            <span>
              A development team that can work within Salesforce’s limits is
              essential to developing and maintaining a functional instance.
              <br />
              <br />
              Salesforce development demands detail and diligence. A single
              transaction can’t take longer than 10 minutes, can’t make more
              than 100 queries, and can’t hold a heap of more than 6MB of data
              at a time.
              <br />
              <br />
              The Devetry approach of simplicity and expertise allows us to work
              within the Salesforce ecosystem to create digital products that
              are easy to manage and use. Contact us today to create a
              customized solution that maximizes Salesforce’s impact on your
              organization.
            </span>
          }
        />
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <FormSection />
      </main>
    </>
  );
};

const SalesforcePage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const blogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const heroImage = data.file;

  return <SalesforcePageTemplate blogs={blogs} heroImage={heroImage}/>;
};

SalesforcePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default SalesforcePage;

export const pageQuery = graphql`
  query SalesforcePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Salesforce/index" } }) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: {
      eq: "typing-on-macbook2.jpg"
    }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
